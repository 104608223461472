<template>
  <div class="position-relative">
    <PageLoader v-if="!pageLoad" />

    <div class="page-title d-flex py-2 justify-content-between">
      <h1 v-if="edit" class="h4 mb-0">
        Редактирование заявки
        <span v-if="currentOrder"
          >№ {{ currentOrder.number }} от
          {{ currentOrder.order_date | parseDate }}</span
        >
      </h1>
      <h1 v-else class="h4 mb-0">Создание заказа на поставку</h1>

      <div class="d-flex align-items-center">
        <BButton class="ml-3" variant="warning" v-b-modal.confirm-delete
          >Удалить
        </BButton>
        <BButton class="ml-3" variant="success" @click="save"
          >Сохранить
        </BButton>
        <BButton class="ml-3" variant="primary" @click="sendApproveOrder"
          >Отправить на согласование
        </BButton>
      </div>
    </div>

    <b-tabs v-model="tabIndex" card>
      <b-tab title="Информация">
        <div class="mb-4">
          <div class="d-flex table-bordered mb-1">
            <div
              class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
              style="width: 250px"
            >
              Срок исполнения по заказу
            </div>
            <div class="px-2 py-1 flex-grow-1">
              <b-form-datepicker
                v-model="sendData.deadline_date"
                class="mb-2"
                locale="ru-RU"
                placeholder="Выберите дату"
                :state="
                  $v.sendData.deadline_date.$error
                    ? $v.sendData.deadline_date.required
                    : null
                "
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              />
            </div>
          </div>
        </div>

        <div class="h5">Заказчик-застройщик</div>

        <div class="mb-4">
          <div class="d-flex table-bordered mb-1">
            <div
              class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
              style="width: 250px"
            >
              Филиал АО «Сетевая компания»
            </div>
            <div class="px-2 py-1 flex-grow-1">
              <!-- <b-form-select
                v-model="sendData.customer.organization_id"
                :state="$v.sendData.customer.organization_id.$error ? $v.sendData.customer.organization_id.required : null"
                :options="organizationsOptions"
              />
              <v-select
                label="text"
                v-bind:appendToBody="true"
                v-bind:options="workAgreementsOptions.slice(1)"
                v-bind:placeholder="workAgreementsOptions.slice(1).find( el => el.value === sendData.customer.work_agreement_id ) ? workAgreementsOptions.slice(1).find( el => el.value === sendData.customer.work_agreement_id ).text : ''"
                v-bind:value="workAgreementsOptions.slice(1).find( el => el.value === sendData.customer.work_agreement_id )"
                v-on:input="value => ( sendData.customer.work_agreement_id = value.value )"
                @search="getWorkAgreementsOptionsByQuery"
              >
                <template #no-options>
                  Нет результатов.
                </template>
              </v-select> -->
              <v-select
                label="text"
                v-bind:class="{
                  'v-select-error': $v.sendData.customer.organization_id.$error,
                }"
                v-bind:appendToBody="true"
                v-bind:options="organizationsOptions.slice(1)"
                v-bind:placeholder="
                  organizationsOptions
                    .slice(1)
                    .find(
                      (el) => el.value === sendData.customer.organization_id
                    )
                    ? organizationsOptions
                        .slice(1)
                        .find(
                          (el) => el.value === sendData.customer.organization_id
                        ).text
                    : ''
                "
                v-bind:value="
                  organizationsOptions
                    .slice(1)
                    .find(
                      (el) => el.value === sendData.customer.organization_id
                    )
                "
                v-on:input="
                  (value) => (sendData.customer.organization_id = value.value)
                "
                @search="getOrganizationsOptionsByQuery"
              >
                <template #no-options> Нет результатов. </template>
              </v-select>
            </div>
          </div>

          <div class="d-flex table-bordered mb-1">
            <div
              class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
              style="width: 250px"
            >
              Договор подряда
            </div>
            <div class="px-2 py-1 flex-grow-1">
              <v-select
                label="text"
                v-bind:class="{
                  'v-select-error':
                    $v.sendData.customer.work_agreement_id.$error,
                }"
                v-bind:appendToBody="true"
                v-bind:options="workAgreementsOptions.slice(1)"
                v-bind:placeholder="
                  workAgreementsOptions
                    .slice(1)
                    .find(
                      (el) => el.value === sendData.customer.work_agreement_id
                    )
                    ? workAgreementsOptions
                        .slice(1)
                        .find(
                          (el) =>
                            el.value === sendData.customer.work_agreement_id
                        ).text
                    : ''
                "
                v-bind:value="
                  workAgreementsOptions
                    .slice(1)
                    .find(
                      (el) => el.value === sendData.customer.work_agreement_id
                    )
                "
                v-on:input="
                  (value) => (sendData.customer.work_agreement_id = value.value)
                "
                @search="getWorkAgreementsOptionsByQuery"
              >
                <template #no-options> Нет результатов. </template>
              </v-select>
            </div>
          </div>

          <div class="d-flex table-bordered mb-1">
            <div
              class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
              style="width: 250px"
            >
              Вид работ
            </div>
            <div class="px-2 py-1 flex-grow-1">
              <b-form-select
                v-model="sendData.customer.work_type"
                :state="
                  $v.sendData.customer.work_type.$error
                    ? $v.sendData.customer.work_type.required
                    : null
                "
                :options="workTypes"
              />
            </div>
          </div>

          <div class="d-flex table-bordered mb-1">
            <div
              class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
              style="width: 250px"
            >
              Объект
            </div>
            <div class="px-2 py-1 flex-grow-1">
              <v-select
                label="text"
                v-bind:class="{
                  'v-select-error': $v.sendData.customer.object_id.$error,
                }"
                v-bind:options="objectsOptions"
                v-bind:value="
                  objectsOptions.find(
                    (el) => el.value === sendData.customer.object_id
                  )
                "
                v-on:input="
                  (value) => (sendData.customer.object_id = value.value)
                "
                :appendToBody="true"
                :placeholder="
                  objectsOptions.find(
                    (el) => el.value === sendData.customer.object_id
                  )
                    ? objectsOptions.find(
                        (el) => el.value === sendData.customer.object_id
                      ).text
                    : ''
                "
                @search="getObjectsOptionsByQuery"
                :filterable="false"
              >
                <template #no-options> Нет результатов. </template>
              </v-select>
            </div>
          </div>

          <div
            v-if="
              subObjectsOptions(sendData.customer.object_id) &&
              subObjectsOptions(sendData.customer.object_id).length
            "
            class="d-flex table-bordered mb-1"
          >
            <div
              class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
              style="width: 250px"
            >
              Подобъект
            </div>
            <div class="px-2 py-1 flex-grow-1">
              <b-form-select
                v-model="sendData.customer.sub_object_id"
                :options="subObjectsOptions(sendData.customer.object_id)"
              />
            </div>
          </div>

          <b-row>
            <b-col cols="4">
              <div class="d-flex table-bordered mb-1">
                <div
                  class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                  style="width: 250px"
                >
                  Начало работ
                </div>
                <div class="px-2 py-1 flex-grow-1">
                  <b-form-datepicker
                    v-model="sendData.customer.work_start_date"
                    :state="
                      $v.sendData.customer.work_start_date.$error
                        ? $v.sendData.customer.work_start_date.required
                        : null
                    "
                    class="mb-2"
                    locale="ru-RU"
                    placeholder="Выберите дату"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                  />
                </div>
              </div>
            </b-col>

            <b-col cols="8">
              <div class="d-flex table-bordered mb-1">
                <div
                  class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                  style="width: 250px"
                >
                  Окончание работ
                </div>
                <div class="px-2 py-1 flex-grow-1">
                  <b-form-datepicker
                    v-model="sendData.customer.work_end_date"
                    :state="
                      $v.sendData.customer.work_end_date.$error
                        ? $v.sendData.customer.work_end_date.required
                        : null
                    "
                    class="mb-2"
                    locale="ru-RU"
                    placeholder="Выберите дату"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                  />
                </div>
              </div>
            </b-col>
          </b-row>
        </div>

        <div class="h5">Поставщик</div>

        <div class="mb-4">
          <div class="Fd-flex table-bordered mb-1">
            <div
              class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
              style="width: 250px"
            >
              Поставщик
            </div>
            <div class="px-2 py-1 flex-grow-1">
              <span v-if="isProvider">
                {{ getContrAgent ? getContrAgent.name : "" }}
              </span>
              <v-select
                v-else
                label="text"
                v-bind:class="{
                  'v-select-error': $v.sendData.provider.contr_agent_id.$error,
                }"
                v-bind:appendToBody="true"
                v-bind:options="contrAgentsOptions.slice(1)"
                v-bind:placeholder="
                  contrAgentsOptions
                    .slice(1)
                    .find((el) => el.value === sendData.provider.contr_agent_id)
                    ? contrAgentsOptions
                        .slice(1)
                        .find(
                          (el) => el.value === sendData.provider.contr_agent_id
                        ).text
                    : ''
                "
                v-bind:value="
                  contrAgentsOptions
                    .slice(1)
                    .find((el) => el.value === sendData.provider.contr_agent_id)
                "
                v-on:input="
                  (value) => (sendData.provider.contr_agent_id = value.value)
                "
                @search="getContrAgentsOptionsByQuery"
              >
                <template #no-options> Нет результатов. </template>
              </v-select>
            </div>
          </div>

          <div class="d-flex table-bordered mb-1">
            <div
              class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
              style="width: 250px"
            >
              Договор поставки
            </div>
            <div class="px-2 py-1 flex-grow-1">
              <b-form-select
                v-model="sendData.provider.contract_id"
                :state="
                  $v.sendData.provider.contract_id.$error
                    ? $v.sendData.provider.contract_id.required
                    : null
                "
                :options="providersContractsOptions.slice(1)"
              />
            </div>
          </div>

          <b-row>
            <b-col cols="4">
              <div class="d-flex table-bordered mb-1">
                <div
                  class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                  style="width: 250px"
                >
                  Контактное лицо
                </div>
                <div class="px-2 py-1 flex-grow-1">
                  <b-form-input
                    v-model="sendData.provider.full_name"
                    :state="
                      $v.sendData.provider.full_name.$error
                        ? $v.sendData.provider.full_name.required
                        : null
                    "
                    trim
                  />
                </div>
              </div>
            </b-col>

            <b-col cols="4">
              <div class="d-flex table-bordered mb-1">
                <div
                  class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                  style="width: 250px"
                >
                  Адрес электронной почты
                </div>
                <div class="px-2 py-1 flex-grow-1">
                  <b-form-input
                    v-model="sendData.provider.email"
                    :state="
                      $v.sendData.provider.email.$error
                        ? $v.sendData.provider.email.required &&
                          $v.sendData.provider.email.email
                        : null
                    "
                    trim
                  />
                </div>
              </div>
            </b-col>

            <b-col cols="4">
              <div class="d-flex table-bordered mb-1">
                <div
                  class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                  style="width: 250px"
                >
                  Телефон
                </div>
                <div class="px-2 py-1 flex-grow-1">
                  <b-form-input
                    v-model="sendData.provider.phone"
                    :state="
                      $v.sendData.provider.phone.$error
                        ? $v.sendData.provider.phone.required
                        : null
                    "
                    v-mask="'+7 (###) ###-##-##'"
                    trim
                  />
                </div>
              </div>
            </b-col>
          </b-row>
        </div>

        <div class="h5">Подрядчик</div>

        <div class="mb-4">
          <div class="d-flex table-bordered mb-1">
            <div
              class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
              style="width: 250px"
            >
              Подрядчик
            </div>
            <div class="px-2 py-1 flex-grow-1">
              <b-form-select
                v-if="!isContractor"
                v-model="sendData.contractor.contr_agent_id"
                :state="
                  $v.sendData.contractor.contr_agent_id.$error
                    ? $v.sendData.contractor.contr_agent_id.required
                    : null
                "
                :options="contrAgentsOptions"
              />
              <span v-else style="height: 29px; line-height: 29px">
                {{ user && user.contr_agent ? user.contr_agent.name : "" }}
              </span>
            </div>
          </div>

          <b-row>
            <b-col cols="4">
              <div class="d-flex table-bordered mb-1">
                <div
                  class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                  style="width: 250px"
                >
                  Контактное лицо
                </div>
                <div class="px-2 py-1 flex-grow-1">
                  <b-form-input
                    v-model="sendData.contractor.full_name"
                    :state="
                      $v.sendData.contractor.full_name.$error
                        ? $v.sendData.contractor.full_name.required
                        : null
                    "
                    trim
                  />
                </div>
              </div>
            </b-col>

            <b-col cols="4">
              <div class="d-flex table-bordered mb-1">
                <div
                  class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                  style="width: 250px"
                >
                  Адрес электронной почты
                </div>
                <div class="px-2 py-1 flex-grow-1">
                  <b-form-input
                    v-model="sendData.contractor.email"
                    :state="
                      $v.sendData.contractor.email.$error
                        ? $v.sendData.contractor.email.required &&
                          $v.sendData.contractor.email.email
                        : null
                    "
                    trim
                  />
                </div>
              </div>
            </b-col>

            <b-col cols="4">
              <div class="d-flex table-bordered mb-1">
                <div
                  class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                  style="width: 250px"
                >
                  Телефон
                </div>
                <div class="px-2 py-1 flex-grow-1">
                  <b-form-input
                    v-model="sendData.contractor.phone"
                    :state="
                      $v.sendData.contractor.phone.$error
                        ? $v.sendData.contractor.phone.required
                        : null
                    "
                    v-mask="'+7 (###) ###-##-##'"
                    trim
                  />
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="8">
              <div class="d-flex table-bordered mb-1">
                <div
                  class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                  style="width: 250px"
                >
                  Ответственный на объекте
                </div>
                <div class="px-2 py-1 flex-grow-1">
                  <b-form-input
                    v-model="sendData.contractor.responsible_full_name"
                    :state="
                      $v.sendData.contractor.responsible_full_name.$error
                        ? $v.sendData.contractor.responsible_full_name.required
                        : null
                    "
                    trim
                  />
                </div>
              </div>
            </b-col>

            <b-col cols="4">
              <div class="d-flex table-bordered mb-1">
                <div
                  class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                  style="width: 250px"
                >
                  Телефон ответственного
                </div>
                <div class="px-2 py-1 flex-grow-1">
                  <b-form-input
                    v-model="sendData.contractor.responsible_phone"
                    :state="
                      $v.sendData.contractor.responsible_phone.$error
                        ? $v.sendData.contractor.responsible_phone.required
                        : null
                    "
                    v-mask="'+7 (###) ###-##-##'"
                    trim
                  />
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-tab>

      <b-tab title="Позиции МТР">
        <CreateTable
          v-model="sendData.positions"
          :fields="positionFields"
          :withIterator="true"
          withTotal="sum"
          :validations="$v.sendData.positions.$each"
          @addRow="addMtrPosition"
        />

        <pre>{{ $v.sendData.positions.$each[0].nomenclature_id.$error }}</pre>
      </b-tab>
    </b-tabs>

    <b-modal id="confirm-delete" title="Удаление заявки" centered>
      <p class="m-0">Вы действительно хотите удалить данную заявку?</p>
      <template #modal-footer="{ hide }">
        <b-button @click="hide()">Нет</b-button>
        <b-button variant="danger" @click="deleteOrder">Да </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { mapState, mapGetters } from "vuex";
import CreateTable from "@/components/Tables/Create";
import PageLoader from "@/components/UI/PageLoader";
import { debounce } from "debounce";
import axios from "axios";

export default {
  name: "OrderForm",
  components: {
    PageLoader,
    CreateTable,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  validations: {
    sendData: {
      deadline_date: { required },
      customer: {
        organization_id: { required },
        work_agreement_id: { required },
        work_type: { required },
        object_id: { required },
        work_start_date: { required },
        work_end_date: { required },
      },
      provider: {
        contr_agent_id: { required },
        contract_id: { required },
        // contact: {
          full_name: { required },
          email: { required, email },
          phone: { required },
        // },
      },
      contractor: {
        contr_agent_id: { required },
        // contact: {
          full_name: { required },
          email: { required, email },
          phone: { required },
        // },
        responsible_full_name: { required },
        responsible_phone: { required },
      },
      positions: {
        $each: {
          nomenclature_id: { required },
          count: { required },
          delivery_time: { required },
          delivery_address: { required },
        },
      },
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      organizations: (state) => state.referenceBooks.organizations,
      workAgreements: (state) => state.referenceBooks.workAgreements,
      objects: (state) => state.referenceBooks.objects,
      contrAgents: (state) => state.referenceBooks.contrAgents,
      providersContracts: (state) => state.referenceBooks.providersContracts,
      workTypes: (state) => state.glossary.work_types,
      currentOrder: (state) => state.orders.currentOrder,
    }),
    ...mapGetters({
      organizationsOptions: "getOrganizationsOptions",
      workAgreementsOptions: "getWorkAgreementsOptions",
      objectsOptions: "getObjectsOptions",
      subObjectsOptions: "getSubObjectsOptions",
      contrAgentsOptions: "getContrAgentsOptions",
      providersContractsOptions: "getProvidersContractsOptions",
    }),
    getContrAgent() {
      return this.contrAgents?.filter(
        (i) => i.id === this.user?.contr_agent?.id
      )[0];
    },
    isProvider() {
      return this.$store.getters.isProvider;
    },
    isContractor() {
      return this.$store.getters.isContractor;
    },
  },
  watch: {
    'sendData.customer.organization_id'( value ) {
        const organization_uuid = this.$store.state.referenceBooks.organizations.find( el => el.id == value ).uuid
        this.$store.dispatch( 'getWorkAgreements', { params: { organization_uuid } } )
        // this.$store.dispatch( 'getProviderContracts', { params: { organization_uuid } } )
    },
    contrAgents: {
      handler() {
        this.setDefaultContrAgent();
      },
      deep: true,
    },
    user: {
      handler() {
        this.setDefaultContrAgent();
      },
      deep: true,
    },
    subObjectsOptions() {
      this.sendData.customer.sub_object_id = null;
    },
  },
  data: () => ({
    tabIndex: 0,
    count: 0,
    sendData: {
      action: "draft",
      deadline_date: "",
      customer: {
        organization_id: "",
        work_agreement_id: "",
        work_type: "",
        object_id: "",
        sub_object_id: null,
        work_start_date: "",
        work_end_date: "",
      },
      provider: {
        contr_agent_id: "",
        contract_id: "",
        // contact: {
          full_name: "",
          email: "",
          phone: "",
        // },
      },
      contractor: {
        contr_agent_id: "",
        // contact: {
          full_name: "",
          email: "",
          phone: "",
        // },
        responsible_full_name: "",
        responsible_phone: "",
      },
      positions: [
        {
          nomenclature_id: "",
          unit_id: "",
          count: "",
          price_without_vat: null,
          delivery_time: "",
          delivery_address: "",
        },
      ],
    },
    positionFields: [
      {
        key: "nomenclature_id",
        label: "Номенклатура",
        full: "Номенклатура",
        type: "select",
      },
      {
        key: "mnemocode_id",
        label: "Мнемокод",
        full: "Мнемокод",
        type: "select",
      },
      {
        key: "unit_id",
        label: "Ед. изм.",
        full: "Единица измерения",
        type: "disabled",
      },
      {
        key: "count",
        label: "Количество",
        full: "Количество",
        type: "number",
      },
      {
        key: "price_without_vat",
        label: "Цена ₽ (без НДС)",
        full: "Цена ₽ (без НДС)",
        type: "number",
      },
      {
        key: "nomenclatureTotalPrice",
        label: "Сумма ₽ (без НДС)",
        full: "Сумма ₽ (без НДС)",
        type: "disabled",
      },
      {
        key: "delivery_time",
        label: "Срок поставки",
        full: "Срок поставки",
        type: "date",
      },
      {
        key: "delivery_address",
        label: "Адрес поставки",
        full: "Адрес поставки",
        type: "text",
      },
    ],
    pageLoad: false,
  }),
  methods: {
    save() {
      this.sendData.contractor.contr_agent_id = this.user.contr_agent.id;
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.$bvToast.toast("Заполните обязательные поля", {
          toaster: "b-toaster-top-right",
          solid: true,
          appendToast: false,
          variant: "danger",
        });
        return;
      } else {
        this.sendOrder();
      }
    },
    deleteOrder() {
      if (this.edit) {
        const id = this.$route.params.id;
        this.$store
          .dispatch("deleteOrder", id)
          .then(() => {
            this.$bvToast.toast("Заказ успешно удален", {
              toaster: "b-toaster-top-right",
              solid: true,
              appendToast: false,
              variant: "success",
            });
            this.$router.push("/purchase-orders");
          })
          .catch(() => {
            this.$bvToast.toast("Что-то пошло не так, попробуйте позднее", {
              toaster: "b-toaster-top-right",
              solid: true,
              appendToast: false,
              variant: "danger",
            });
          });
      } else {
        this.$router.push("/purchase-orders");
      }
    },
    sendApproveOrder() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.$bvToast.toast("Заполните обязательные поля", {
          toaster: "b-toaster-top-right",
          solid: true,
          appendToast: false,
          variant: "danger",
        });
        return;
      }
      this.sendData.action = "approve";
      this.sendOrder("Заявка отправлена на согласование");
    },
    addMtrPosition() {
      this.sendData.positions.push({
        nomenclature_id: "",
        unit_id: "",
        count: "",
        price_without_vat: null,
        delivery_time: "",
        delivery_address: "",
      });
      this.count++;
    },
    setDefaultContrAgent() {
      if (this.isProvider) {
        this.sendData.provider.contr_agent_id = this.getContrAgent
          ? this.getContrAgent.id
          : null;
      }
      if (this.isContractor) {
        this.sendData.contractor.contr_agent_id = this.getContrAgent
          ? this.getContrAgent.id
          : this.user.contr_agent.id || null;
      }
    },
    sendOrder(successText = "Заявка успешно сохранена") {
      if (this.edit) {
        const id = this.$route.params.id;
        this.$store
          .dispatch("editOrder", {
            id,
            data: this.sendData,
          })
          .then(() => {
            this.sendSuccess(successText);
          })
          .catch((err) => {
            this.sendError(err);
          });
      } else {
        this.$store
          .dispatch("sendApproveOrder", this.sendData)
          .then(() => {
            this.sendSuccess(successText);
          })
          .catch((err) => {
            this.sendError(err);
          });
      }
    },
    sendSuccess(successText) {
      this.$bvToast.toast(successText, {
        toaster: "b-toaster-top-right",
        solid: true,
        appendToast: false,
        variant: "success",
      });
      this.$router.push("/purchase-orders");
    },
    sendError(err) {
      const errText =
        err.response.status === 422
          ? "Проверьте правильность заполненных полей"
          : "Ошибка сервера. Попробуйте позднее.";
      this.$bvToast.toast(errText, {
        toaster: "b-toaster-top-right",
        solid: true,
        appendToast: false,
        variant: "danger",
      });
    },

    getObjectsOptionsByQuery(search, loading) {
      if (search.length > 1) {
        loading(true);
        this.getObjectsOptionsSearch(search, loading, this);
      }
    },
    getObjectsOptionsSearch: debounce(async function (search, loading, vm) {
      axios
        .get("/references/objects", { params: { search } })
        .then((r) =>
          vm.$store.commit("setReferenceBooks", {
            book: "objects",
            data: r.data.data,
          })
        )
        .finally(() => loading(false));
    }, 800),

    getContrAgentsOptionsByQuery(search, loading) {
      if (search.length > 1) {
        loading(true);
        this.getContrAgentsOptionsSearch(search, loading, this);
      }
    },
    getContrAgentsOptionsSearch: debounce(async function (search, loading, vm) {
      axios
        .get("/references/contr_agents", { params: { name: search } })
        .then((r) =>
          vm.$store.commit("setReferenceBooks", {
            book: "contrAgents",
            data: r.data.data.data,
          })
        )
        .finally(() => loading(false));
    }, 800),

    getWorkAgreementsOptionsByQuery(search, loading) {
      if (search.length > 1) {
        loading(true);
        this.getWorkAgreementsOptionsSearch(search, loading, this);
      }
    },
    getWorkAgreementsOptionsSearch: debounce(async function (
      search,
      loading,
      vm
    ) {
      axios
        .get("/references/work_agreements", { params: { number: search } })
        .then((r) =>
          vm.$store.commit("setReferenceBooks", {
            book: "workAgreements",
            data: r.data.data,
          })
        )
        .finally(() => loading(false));
    },
    800),

    getOrganizationsOptionsByQuery(search, loading) {
      if (search.length > 1) {
        loading(true);
        this.getOrganizationsOptionsSearch(search, loading, this);
      }
    },
    getOrganizationsOptionsSearch: debounce(async function (
      search,
      loading,
      vm
    ) {
      axios
        .get("/references/organizations", { params: { name: search } })
        .then((r) =>
          vm.$store.commit("setReferenceBooks", {
            book: "organizations",
            data: r.data.data,
          })
        )
        .finally(() => loading(false));
    },
    800),
  },
  async mounted() {
    const id = this.$route.params.id;
    if (this.edit) {
      await this.$store.dispatch("getOrder", id);
      for (let key in this.currentOrder) {
        this.sendData[key] = this.currentOrder[key];
      }
    }
    this.setDefaultContrAgent();
    this.pageLoad = true;
  },
};
</script>

<style scoped>
</style>
